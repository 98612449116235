var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredItems,"hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('tr',_vm._l((props.headers),function(item,idx){return _c('th',{key:("header_" + idx),staticClass:"text-center",style:({
            minWidth: ((item.minWidth) + "px"),
            maxWidth: ((item.maxWidth) + "px"),
            width: ((item.width) + "px"),
            verticalAlign: 'middle'
          })},[_c('div',{staticClass:"d-flex align-center"},[(item.filterable)?_c('div',{staticClass:"pt-4 pb-2 header-col"},[(item.filterable instanceof Array)?_c('v-select',{attrs:{"label":props.headers[idx].text,"items":item.filterable,"multiple":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.onFilter(item.value, props.headers[idx].filterValue)}},model:{value:(props.headers[idx].filterValue),callback:function ($$v) {_vm.$set(props.headers[idx], "filterValue", $$v)},expression:"props.headers[idx].filterValue"}},[_vm._v(" SELECT ")]):_c('v-text-field',{attrs:{"label":props.headers[idx].text,"outlined":"","hide-details":"","dense":""},on:{"input":function($event){return _vm.onFilter(item.value, props.headers[idx].filterValue)}},model:{value:(props.headers[idx].filterValue),callback:function ($$v) {_vm.$set(props.headers[idx], "filterValue", $$v)},expression:"props.headers[idx].filterValue"}})],1):_c('div',{staticClass:"v-label pb-4 pt-4"},[_c('span',[_vm._v(_vm._s(props.headers[idx].text))])]),(props.headers[idx].sortable)?_c('div',[_c('v-btn',{staticClass:"sort-btn",class:{
                  'sort-active': (props.options.sortBy[0] === item.value),
                  'sort-desc': ((props.options.sortBy[0] === item.value)
                    && props.options.sortDesc[0]),
                },attrs:{"small":"","icon":""},on:{"click":function($event){return on.sort(item.value)}}},[_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1)],1):_vm._e()])])}),0)])]}},_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
                var item = ref.item;
return [_vm._t(header.value,[_vm._v(" "+_vm._s(item[header.value])+" ")],{"item":item})]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }