<template>
  <v-dialog
    max-width="600"
    persistent
    :value="show">
    <v-card>
      <v-card-title>
        <span>펌웨어 추가</span>
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('update:show', false)"
          icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-left">
        <v-select
          v-model="form.track"
          :items="track.items">
        </v-select>
        <v-text-field
          v-model="form.model"
          label="모델명">
        </v-text-field>
        <v-text-field
          v-model="form.version"
          label="버전">
        </v-text-field>
        <v-textarea
          v-model="form.memo"
          label="메모">
        </v-textarea>
        <drop-zone
          :loading="loading"
          @change="onChange">
        </drop-zone>
        <v-btn
          @click="onClickAddFirmware"
          color="primary"
          block>
          등록
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DropZone from '@/components/input/DropZone.vue';

export default {
  name: 'DlgAddFirmware',
  components: {
    DropZone,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  comments: {
    DropZone,
  },
  data: () => ({
    loading: false,
    track: {
      items: [
        { text: '프로덕션', value: 0 },
        { text: '베타', value: 1 },
      ],
    },
    form: {
      track: 0,
      model: '',
      version: '',
      memo: '',
      data: '',
    },
  }),
  sockets: {
    'term.firmware.add': function _(data) {
      if (data.result === 'success') {
        this.$emit('added');
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }
    },
  },
  methods: {
    onChange(file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binary = event.target.result;
        this.form.data = btoa(binary);
      };

      reader.readAsBinaryString(file);
    },
    onClickAddFirmware() {
      this.$socket.emit('term.firmware.add', this.form);
    },
  },
};
</script>

<style scoped>

</style>
