<template>
  <layout-card
    title="관제계정목록">
    <template
      v-slot:body>
      <div
        class="d-flex flex-column">
        <div
          class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            @click="newAccount.show = true"
            color="primary">
            <v-icon left>
              mdi-account-plus
            </v-icon>
            관제계정 추가
          </v-btn>
        </div>
        <div>
          <sort-table
            :headers="headers"
            :items="items"
            :filtered-items.sync="filteredItems">
            <template v-slot:name="{ item }">
              <v-edit-dialog
                :return-value.sync="item.name"
                large
                persistent
                save-text="수정"
                cancel-text="취소"
                @open="editor.name=item.name"
                @save="onUpdate({ ...item, name: editor.name })">
                <span>{{ item.name }}</span>
                <template v-slot:input>
                  <v-text-field
                    v-model="editor.name"
                    label="이름"
                    single-line>
                  </v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:action="{ item }">
              <v-tooltip
                top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="confirm.reset.id = item.id; confirm.reset.show = true;"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon>
                    <v-icon>
                      mdi-account-key
                    </v-icon>
                  </v-btn>
                </template>
                <span>계정 암호 초기화</span>
              </v-tooltip>
              <v-tooltip
                top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="confirm.remove.id = item.id; confirm.remove.show = true;"
                    v-bind="attrs"
                    v-on="on"
                    small
                    icon>
                    <v-icon>
                      mdi-account-remove
                    </v-icon>
                  </v-btn>
                </template>
                <span>계정 제거</span>
              </v-tooltip>
            </template>
          </sort-table>
        </div>
      </div>
      <dlg-new-ctrl-account
        @submit="onNewAccount"
        v-bind="newAccount"
        v-if="newAccount.show"
        :show.sync="newAccount.show">
      </dlg-new-ctrl-account>
      <dlg-confirm
        @response="onResetPassword"
        :show.sync="confirm.reset.show"
        :title="confirm.reset.title"
        :message="confirm.reset.message">
      </dlg-confirm>
      <dlg-confirm
        @response="onRemoveAccount"
        :show.sync="confirm.remove.show"
        :title="confirm.remove.title"
        :message="confirm.remove.message">
      </dlg-confirm>
    </template>
  </layout-card>
</template>

<script>
import { mapGetters } from 'vuex';
import LayoutCard from '@/components/card/LayoutCard.vue';
import SortTable from '@/components/output/SortTable.vue';
import DlgNewCtrlAccount from '@/components/dialog/DlgNewCtrlAccount.vue';
import DlgConfirm from '@/components/dialog/DlgConfirm.vue';

export default {
  name: 'CardCtrlUserList',
  components: {
    LayoutCard,
    SortTable,
    DlgNewCtrlAccount,
    DlgConfirm,
  },
  data: () => ({
    headers: [
      {
        text: 'ID',
        value: 'id',
        filterable: true,
        filterValue: '',
        sortable: true,
      },
      {
        text: '이름',
        value: 'name',
        filterable: true,
        filterValue: '',
        sortable: true,
      },
      {
        text: '동작',
        value: 'action',
      },
    ],
    items: [],
    filteredItems: [],
    newAccount: {
      show: false,
      step: 0,
      password: '',
      loading: false,
    },
    confirm: {
      reset: {
        show: false,
        title: '암호 재설정',
        message: '정말로 암호를 재설정 하시겠습니까?',
        id: '',
      },
      remove: {
        show: false,
        title: '계정 제거',
        message: '정말로 계정을 제거 하시겠습니까?',
        id: '',
      },
    },
    editor: {
      name: '',
    },
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler() {
        this.getUserList();
      },
    },
    'newAccount.show': function _() {
      if (!this.newAccount.show) {
        this.newAccount.step = 0;
        this.newAccount.password = '';
      }
    },
  },
  sockets: {
    'term.users.list.ctrl.all.get': function _(data) {
      if (data.result === 'success') {
        this.items = data.items;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
    'term.user.ctrl.add': function _(data) {
      if (data.result === 'success') {
        this.newAccount.password = data.password;
        this.newAccount.step = 1;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }

      this.newAccount.loading = false;
      this.getUserList();
    },
    'term.user.ctrl.password.reset': function _(data) {
      if (data.result === 'success') {
        this.newAccount.show = true;
        this.newAccount.password = data.password;
        this.newAccount.step = 1;
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
    'term.user.ctrl.remove': function _(data) {
      if (data.result === 'success') {
        this.getUserList();
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
    'term.user.ctrl.info.update': function _(data) {
      if (data.result === 'success') {
        this.getUserList();
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }
    },
  },
  methods: {
    getUserList() {
      this.$socket.emit('term.users.list.ctrl.all.get', {});
    },
    onNewAccount(form) {
      this.newAccount.loading = true;
      this.$socket.emit('term.user.ctrl.add', form);
    },
    onResetPassword(resp) {
      if (resp) {
        this.$socket.emit('term.user.ctrl.password.reset', {
          id: this.confirm.reset.id,
        });
      }
    },
    onRemoveAccount(resp) {
      if (resp) {
        this.$socket.emit('term.user.ctrl.remove', {
          id: this.confirm.remove.id,
        });
      }
    },
    onUpdate(item) {
      this.$socket.emit('term.user.ctrl.info.update', {
        id: item.id,
        name: item.name,
      });
    },
  },
};
</script>

<style scoped>

</style>
