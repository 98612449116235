<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="filteredItems"
    hide-default-header>
    <template v-slot:header="{ props, on }">
      <thead>
        <tr>
          <th
            v-for="(item, idx) in props.headers"
            :key="`header_${idx}`"
            :style="{
              minWidth: `${item.minWidth}px`,
              maxWidth: `${item.maxWidth}px`,
              width: `${item.width}px`,
              verticalAlign: 'middle'
            }"
            class="text-center">
            <div class="d-flex align-center">
              <div
                v-if="item.filterable"
                class="pt-4 pb-2 header-col">
                <v-select
                  v-if="item.filterable instanceof Array"
                  :label="props.headers[idx].text"
                  @change="onFilter(item.value, props.headers[idx].filterValue)"
                  v-model="props.headers[idx].filterValue"
                  :items="item.filterable"
                  multiple
                  hide-details
                  dense>
                  SELECT
                </v-select>
                <v-text-field
                  v-else
                  @input="onFilter(item.value, props.headers[idx].filterValue)"
                  :label="props.headers[idx].text"
                  v-model="props.headers[idx].filterValue"
                  outlined
                  hide-details
                  dense>
                </v-text-field>
              </div>
              <div
                class="v-label pb-4 pt-4"
                v-else>
                <span>{{ props.headers[idx].text }}</span>
              </div>
              <div
                v-if="props.headers[idx].sortable">
                <v-btn
                  @click="on.sort(item.value)"
                  class="sort-btn"
                  :class="{
                    'sort-active': (props.options.sortBy[0] === item.value),
                    'sort-desc': ((props.options.sortBy[0] === item.value)
                      && props.options.sortDesc[0]),
                  }"
                  small
                  icon>
                  <v-icon>
                    mdi-chevron-up
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </th>
        </tr>
      </thead>
    </template>
    <template
      v-for="header in headers"
      v-slot:[`item.${header.value}`]="{ item }">
      <slot
        :name="header.value"
        :item="item">
        {{ item[header.value] }}
      </slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'SortTable',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    filteredItems: {
      type: Array,
      default: () => ([]),
    },
  },
  watch: {
    items: {
      immediate: true,
      handler() {
        this.onFilter();
      },
    },
  },
  methods: {
    onFilter() {
      const filteredItems = this.items.filter((item) => {
        let found = true;

        this.headers.forEach((header) => {
          if (header.filterable != null && header.filterable) {
            const value = item[header.value];

            if (value != null) {
              const { filterable, filterValue } = header;

              if (filterable instanceof Array) {
                found = found && filterValue.includes(value);
              } else if (filterable instanceof Function) {
                // found = found && filterable(item, hdrValue, filterValue, this);
              } else {
                const str = value.toString();
                found = found && ((str != null && str.includes(filterValue))
                  || filterValue === '');
              }
            } else {
              found = found && (value == null && header.filterValue === '');
            }
          }
        });

        return found;
      });

      this.$emit('update:filteredItems', filteredItems);
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-active {
  opacity: 1 !important;
}

.sort-desc {
  transform: rotate(-180deg);
}

.sort-btn {
  opacity: .3;

  &:hover {
    opacity: .75;
  }
}

.header-col {
  width: 100%;
}
</style>
