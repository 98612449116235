<template>
  <v-card
    tile
    flat>
    <v-card-title>
      <v-spacer>
      </v-spacer>
      <v-btn
        @click="onShowAddFirmware"
        color="primary"
        x-small
        fab>
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        multi-sort
        :loading="firmwareList.loading"
        :headers="firmwareList.headers"
        :items="firmwareList.items">
        <template v-slot:item.track="{ item }">
          <div>
            {{ toTrackName(item.track) }}
          </div>
        </template>
        <template v-slot:item.timestamp="{ item }">
          <div>
            {{ toLocalDate(item.timestamp) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <dlg-add-firmware
      @added="onAddFirmware"
      :show.sync="dialog.addFirmware.show"
      v-if="dialog.addFirmware.show">
    </dlg-add-firmware>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import * as time from '@kim5257/js-time';
import DlgAddFirmware from '@/components/dialog/DlgAddFirmware.vue';

export default {
  name: 'Firmware',
  components: {
    DlgAddFirmware,
  },
  data: () => ({
    firmwareList: {
      loading: false,
      headers: [
        {
          text: 'No',
          value: 'no',
        },
        {
          text: '모델',
          value: 'model',
        },
        {
          text: '버전',
          value: 'version',
        },
        {
          text: '트랙',
          value: 'track',
        },
        {
          text: '메모',
          value: 'memo',
        },
        {
          text: '등록 시간',
          value: 'timestamp',
        },
      ],
      items: [],
    },
    dialog: {
      addFirmware: {
        show: false,
      },
    },
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler(value) {
        if (value != null) {
          this.getFirmwareList();
        }
      },
    },
  },
  sockets: {
    'term.firmware.list.get.recent': function _(data) {
      if (data.result === 'success') {
        this.firmwareList.items = data.items;
        this.firmwareList.loading = false;
      } else {
        this.$store.dispatch('alert/setAlert', {
          color: 'error',
          message: data.message,
        });
      }
    },
  },
  methods: {
    getFirmwareList() {
      this.firmwareList.loading = true;
      this.$socket.emit('term.firmware.list.get.recent', {});
    },
    toTrackName(track) {
      let ret = 'Unknown';

      switch (track) {
        case 0: ret = '프로덕션'; break;
        case 1: ret = '베타'; break;
        default: break;
      }

      return ret;
    },
    toLocalDate(timestamp) {
      return (timestamp != null) ? time.localTimeString(timestamp) : '-';
    },
    onShowAddFirmware() {
      this.dialog.addFirmware.show = true;
    },
    onAddFirmware() {
      this.dialog.addFirmware.show = false;

      this.getFirmwareList();
    },
  },
};
</script>

<style scoped>

</style>
