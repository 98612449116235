<template>
  <v-card
    tile
    flat>
    <v-card-text>
      <v-data-table
        :loading="deviceList.loading"
        :headers="deviceList.headers"
        :items="deviceList.filteredItems"
        hide-default-header>
        <template v-slot:header="{ props, on }">
          <thead>
            <tr>
              <th
                v-for="(item, idx) in props.headers"
                :key="`header_${idx}`"
                :style="{
                    minWidth: `${item.minWidth}px`,
                    maxWidth: `${item.maxWidth}px`,
                    width: `${item.width}px`,
                    verticalAlign: 'middle'
                    }"
                class="text-center">
                <div class="d-flex align-center">
                  <div
                    v-if="item.filterable"
                    class="pt-4 pb-2 header-col">
                    <v-select
                      v-if="item.filterable instanceof Array"
                      :label="props.headers[idx].text"
                      @change="onFilter(item.value, props.headers[idx].filterValue)"
                      v-model="props.headers[idx].filterValue"
                      :items="item.filterable"
                      multiple
                      hide-details
                      dense>
                      SELECT
                    </v-select>
                    <v-text-field
                      v-else
                      @input="onFilter(item.value, props.headers[idx].filterValue)"
                      :label="props.headers[idx].text"
                      v-model="props.headers[idx].filterValue"
                      outlined
                      hide-details
                      dense>
                    </v-text-field>
                  </div>
                  <div
                    class="v-label pb-4 pt-4"
                    v-else>
                    <span>{{ props.headers[idx].text }}</span>
                  </div>
                  <div
                    v-if="props.headers[idx].sortable">
                    <v-btn
                      @click="on.sort(item.value)"
                      class="sort-btn"
                      :class="{
                          'sort-active': (props.options.sortBy[0] === item.value),
                          'sort-desc': ((props.options.sortBy[0] === item.value)
                            && props.options.sortDesc[0]),
                          }"
                      small
                      icon>
                      <v-icon>
                        mdi-chevron-up
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item.router_usage="{ item }">
          {{ toUsageString(item.router_usage) }}
        </template>
        <template v-slot:item.voltage="{ item }">
          <div>
            {{ (item.voltage != null) ? (item.voltage / 1000) : '-' }}
          </div>
        </template>
        <template v-slot:item.uptime="{ item }">
          <div>
            {{ toUptimeText(item.uptime) }}
          </div>
        </template>
        <template v-slot:item.timestamp="{ item }">
          <div>
            {{ toLocalDate(item.timestamp) }}
          </div>
        </template>
        <template v-slot:item.stat="{ item }">
          <div>
            <v-icon>
              {{ (item.stat === 'connected') ? 'mdi-signal' : 'mdi-signal-off' }}
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import * as time from '@kim5257/js-time';

export default {
  name: 'DeviceListAll',
  data: () => ({
    deviceList: {
      loading: false,
      headers: [
        {
          text: 'Serial',
          value: 'serial',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 150,
        },
        {
          text: '모델명',
          value: 'model',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 100,
        },
        {
          text: '버전',
          value: 'version',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 90,
        },
        {
          text: '라우터 SSID',
          value: 'ssid',
          filterable: true,
          filterValue: '',
          sortable: true,
        },
        {
          text: '라우터 MAC',
          value: 'router_mac',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 150,
        },
        {
          text: '라우터 RSSI',
          value: 'router_rsrp',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 150,
        },
        {
          text: '라우터 사용량',
          value: 'router_usage',
          filterable: false,
          sortable: true,
          width: 80,
        },
        {
          text: '용량',
          value: 'storage',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 90,
        },
        {
          text: '전압',
          value: 'voltage',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 90,
        },
        {
          text: '동작시간',
          value: 'uptime',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 120,
        },
        {
          text: '온도',
          value: 'temperature',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 90,
        },
        {
          text: '등록 사용자',
          value: 'user_id',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 130,
        },
        {
          text: '마지막 업데이트',
          value: 'timestamp',
          filterable: true,
          filterValue: '',
          sortable: true,
          width: 160,
        },
        {
          text: '상태',
          value: 'stat',
          filterable: [
            { text: '온라인', value: 'connected' },
            { text: '오프라인', value: 'disconnected' },
          ],
          filterValue: ['connected', 'disconnected'],
          sortable: true,
          width: 120,
        },
      ],
      items: [],
      filteredItems: [],
      selectedItem: null,
    },
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
    }),
  },
  watch: {
    loggedIn: {
      immediate: true,
      handler() {
        this.getDevicesAll();
      },
    },
  },
  sockets: {
    'term.device.list.all.get': function _(data) {
      if (data.result === 'success') {
        this.deviceList.items = data.items;
        this.onFilter();
      } else {
        this.$store.dispatch('alert/setAlert', {
          message: data.message,
          color: 'error',
        });
      }

      this.deviceList.loading = false;
    },
  },
  methods: {
    toUptimeText(uptime) {
      let text = '-';

      if (uptime != null) {
        const days = Math.floor(uptime / (60 * 60 * 24));
        const seconds = uptime - (days * 60 * 60 * 24);
        const date = new Date(0);
        date.setSeconds(seconds);

        text = `${days}일 ${date.toISOString().substr(11, 8)}`;
      }

      return text;
    },
    toUsageString(usage) {
      let ret = usage / 1024;
      let unit = 'MB';

      if (ret > 1024) {
        ret /= 1024;
        unit = 'GB';
      }

      ret = Math.round(ret * 10) / 10;

      return `${ret} ${unit}`;
    },
    toLocalDate(timestamp) {
      return (timestamp != null) ? time.localTimeString(timestamp) : '-';
    },
    getDevicesAll() {
      this.deviceList.loading = true;
      this.$socket.emit('term.device.list.all.get', {});
    },
    onFilter() {
      this.deviceList.filteredItems = this.deviceList.items.filter((item) => {
        let found = true;

        this.deviceList.headers.forEach((header) => {
          if (header.filterable != null && header.filterable) {
            const value = item[header.value];

            if (value != null) {
              const { filterable, filterValue } = header;

              if (filterable instanceof Array) {
                found = found && filterValue.includes(value);
              } else if (filterable instanceof Function) {
                // found = found && filterable(item, hdrValue, filterValue, this);
              } else {
                const str = value.toString();
                found = found && ((str != null && str.includes(filterValue))
                  || filterValue === '');
              }
            } else {
              found = found && (value == null && header.filterValue === '');
            }
          }
        });

        return found;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sort-active {
  opacity: 1 !important;
}

.sort-desc {
  transform: rotate(-180deg);
}

.sort-btn {
  opacity: .3;

  &:hover {
    opacity: .75;
  }
}

.header-col {
  width: 100%;
}
</style>
