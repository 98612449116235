<template>
  <div
    class="d-flex">
    <div
      class="flex-grow-1">
      <card-ctrl-user-list>
      </card-ctrl-user-list>
    </div>
  </div>
</template>

<script>
import CardCtrlUserList from '@/components/card/ctrluserlist/CardCtrlUserList.vue';

export default {
  name: 'CtrlUserList',
  components: {
    CardCtrlUserList,
  },
};
</script>

<style scoped>

</style>
