<template>
  <layout-dialog
    title="새 관제 계정"
    :show.sync="showFlag"
    :max-width="400">
    <template v-slot:body>
      <template
        v-if="step === 0">
        <div
          class="d-flex flex-column">
          <v-form
            @submit.prevent="$emit('submit', form)">
            <div>
              <v-text-field
                label="ID"
                v-model="form.id">
              </v-text-field>
              <v-text-field
                label="이름"
                v-model="form.name">
              </v-text-field>
            </div>
            <div
              class="d-flex">
              <v-spacer>
              </v-spacer>
              <v-btn
                @click="showFlag = false"
                class="mr-2"
                color="secondary">
                <v-icon left>
                  mdi-cancel
                </v-icon>
                취소
              </v-btn>
              <v-btn
                :loading="loading"
                type="submit"
                color="primary">
                <v-icon left>
                  mdi-check
                </v-icon>
                확인
              </v-btn>
            </div>
          </v-form>
        </div>
      </template>
      <template
        v-else>
        <div>
          <div
            class="password-content mb-4">
            <div
              class="content-message mb-2">
              발급된 {{ form.id }} 계정의 초기 비밀번호는
            </div>
            <div
              class="content-value">
              {{ password }}
            </div>
            <div
              class="content-message mt-2">
              입니다.
            </div>
          </div>
          <div>
            <v-btn
              @click="showFlag = false"
              color="success">
              <v-icon left>
                mdi-check
              </v-icon>
              확인
            </v-btn>
          </div>
        </div>
      </template>
    </template>
  </layout-dialog>
</template>

<script>
import LayoutDialog from '@/components/dialog/LayoutDialog.vue';

export default {
  name: 'DlgNewCtrlAccount',
  components: {
    LayoutDialog,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    step: {
      type: Number,
      default: 0,
    },
    password: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      id: '',
      name: '',
    },
  }),
  computed: {
    showFlag: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  sockets: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.password-content {
  .content-message {
    font-size: 1rem;
  }

  .content-value {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
</style>
